import { link } from "fs";
import { useState, useEffect, memo } from "react";
import { text } from "stream/consumers";
import { displayPartsToString } from "typescript";

interface IMemoryButton {
    color: string;
    visible: boolean;
    found: boolean;
    disabled: boolean;
}

function App() {

    const colors = [
        'lightblue',
        'lightblue',
        '#ba5681',
        '#ba5681',
        'lightgreen',
        'lightgreen',
        '#336fab',
        '#336fab',
        'pink',
        'pink',
        'orange',
        'orange',
        '#79cf65',
        '#79cf65',
        '#c265cf',
        '#c265cf',
    ];

    const [intervalId, setIntervalId] = useState<NodeJS.Timer|null>(null);
    const [tempsId, setTempsId] = useState<NodeJS.Timer|null>(null);
    const [timer, setTimer] = useState(0);
    const [memoryButtons, setMemoryButtons] = useState<IMemoryButton[]>([]);
    const [temps, setTemps] = useState(0);
    const [points, setPoints] = useState(0);
    const [startTemps, setStartTemps] = useState(0);
    const [endGame, setEndGame] = useState(0);
    const [sec, setSec] = useState(0);

    function updateTemps(secondes: any) {
        setStartTemps(1);
        setTemps(secondes +3);
        onStart();

    }

    function resetButton()  {
        setMemoryButtons(memoryButtons.map((b, i) => ({ ...b, visible: false, found: false })));
        let sortedColors = shuffle(colors);
        setMemoryButtons(sortedColors.map(color => ({ color, found: false, visible: false, disabled: false })));
        setTimer(1);
    }

    const shuffle = (array: string[]) => {

        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
      
        return array;
    };

    function onStart() {
        setEndGame(0);
        let sortedColors = shuffle(colors);
        setMemoryButtons(sortedColors.map(color => ({ color, found: false, visible: false, disabled: false })));
        setTimer(1);
        setPoints(0);

    }

    useEffect(() => {


        if(memoryButtons.length > 0 && memoryButtons.every(b => b.found === true)){
            resetButton();
        }


    }, [memoryButtons])

    useEffect(() => {

        if (startTemps === 1) {
            setStartTemps(0);
            let id = setInterval(() => setTemps(value => value -= 1), 1000);
            setTempsId(id);
        }

        if (temps === 0 && tempsId) {
            clearInterval(tempsId);

            setMemoryButtons([]);
            setEndGame(1);
        }

    }, [temps]);


    useEffect(() => {

        if (timer === 1) {
            let id = setInterval(() => setTimer(value => value -= 1), 1000);                                        
            setIntervalId(id);
        }

        if (timer === 0 && intervalId) {

            clearInterval(intervalId);
            setMemoryButtons(prevMemoryButtons => prevMemoryButtons.map(memoryButton => ({ ...memoryButton, visible: true })));

            setTimeout(() => {
                setMemoryButtons(prevMemoryButtons => prevMemoryButtons.map(memoryButton => ({ ...memoryButton, visible: false })));
            }, 1000);
        }

    }, [timer]);

    return (
        <>
        <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <h2>{timer}</h2>
            
            <div style={{ padding: 24, display: 'grid', gridTemplateColumns: '200px 200px 200px 200px', gridTemplateRows: '200px 200px 200px', gap: 5 }}>
            {endGame ? (
                <h2 style={{gridColumn: '1/5', gridRow: '1/5', textAlign: 'center', color: 'white', border: '2px black', fontSize: '75px', position: 'absolute', left: '470px', top: '350px'}}>!* PARTIE TERMINÉE *!</h2>
            )
        : (
            memoryButtons.map((memoryButton, index) => (
                <div key={index}>
                    <MemoryButton
                        index={index}
                        color={memoryButton.color}
                        found={memoryButton.found}
                        visible={memoryButton.visible}
                        disabled={memoryButton.disabled}
                        memoryButtons={memoryButtons}
                        setMemoryButtons={setMemoryButtons}
                        setPoints={setPoints}
                    />
                </div>
            ))
        )}
                
                <div className="Temps">
                    <h2>Temps :</h2>
                    <button className="btn" onClick={() => updateTemps(30)} disabled={timer > 0}>30s</button>
                    <button className="btn" onClick={() => updateTemps(45)} disabled={timer > 0}>45s</button>
                    <button className="btn" onClick={() => updateTemps(60)} disabled={timer > 0}>1min</button>
                    <button className="btn" onClick={() => updateTemps(90)} disabled={timer > 0}>1min 30s</button>
                    <button className="btn" onClick={() => updateTemps(120)} disabled={timer > 0}>2min</button>
                    <button className="btn" onClick={() => updateTemps(150)} disabled={timer > 0}>2min 30s</button>
                </div>
                <div>
                    <h2 style={{  position: 'absolute', top: '350px', right: '100px' }}>Points :</h2>
                    <h2 style={{  position: 'absolute', top: '400px', right: '130px' }}>{points}</h2>
                </div>
            </div>
            {/* ------------------ START ------------------ */}
            <div style={{ textAlign: 'center' }}>
                <h2>TIMER : {temps}</h2>
            </div>
        </div>
        </>
    );
}

interface IMemoryButtonProps extends IMemoryButton {
    index: number;
    memoryButtons: IMemoryButton[];
    setMemoryButtons: React.Dispatch<React.SetStateAction<IMemoryButton[]>>;
    setPoints: React.Dispatch<React.SetStateAction<number>>;
}



function MemoryButton(props: IMemoryButtonProps) {

    const { memoryButtons, setMemoryButtons, found, setPoints, visible, disabled, color, index} = props;

    function handleButton() {

        let buttons = [...memoryButtons];
        let indexButtonVisible = memoryButtons.findIndex(b => b.visible);

        if (indexButtonVisible !== -1) {

            if (memoryButtons[indexButtonVisible].color === memoryButtons[index].color) {

                setMemoryButtons(buttons.map((b, i) => (i === index || i === indexButtonVisible) ? ({ ...b, visible: false, found: true }) : ({ ...b })));
                setPoints((prevState) => (prevState +1));

            }
            else {

                setMemoryButtons(buttons.map((b, i) => i === index ? ({ ...b, visible: true, disabled: true }) : ({ ...b, disabled: true })));



                setTimeout(() => {
                    setMemoryButtons(buttons.map((b, i) => ({ ...b, visible: false, disabled: false })));
                }, 1000);
            }
        }
        else {
            buttons[index].visible = true;
            setMemoryButtons(buttons);
        }
    }

    return (
        <button
            onClick={handleButton}
            disabled={disabled || found || visible}
            style={{
                borderRadius: 8,
                width: '170px',
                height: '170px',
                boxShadow: '-7px 7px 3px gray',
                cursor: (found || visible) ? 'default' : 'pointer',
                backgroundColor: (found || visible) ? color : 'lightgray',
                border: found ? '1px solid gold' : 'none',
                
            }}
        >
        </button>
    );
}

export default App;